import {initializeApp} from 'firebase/app'
import { getAuth } from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBsZWjltZfOCL6GupAXZ1Ew4o9lUgoSOSQ",
  authDomain: "blog-mdn.firebaseapp.com",
  projectId: "blog-mdn",
  storageBucket: "blog-mdn.appspot.com",
  messagingSenderId: "466865983949",
  appId: "1:466865983949:web:5bd407345a14c9feb67484"
};

const app = initializeApp(firebaseConfig);

  export const storage = getStorage(app);
  export const db = getFirestore(app);
  export const auth =getAuth(app);