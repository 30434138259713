import { signInWithGooglePopup } from "../../firebase.utils"
import '../../App.css'
import { useNavigate } from 'react-router-dom'

const SignIn = () => {

const navigate = useNavigate()

const logGoogleUser = async () => {
  
        const response = await signInWithGooglePopup();
        console.log(response);
        navigate('/');

}

return (
        <div className="register">
          <div className="login__google">
            <button onClick={logGoogleUser}>Sign In With Google</button>

          </div>
        </div>
    )
}
export default SignIn;

