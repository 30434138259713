import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import '../App.css'
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";

export default function Navbar() {
  const [user] = useAuthState(auth);
  return (
    <div className="fixed-bottom border" style={{ backgroundColor: "whitesmoke" }}>
      <nav className="navbar__nativo">
        {
            !user ? <Link to={'/register'}>Logar</Link> :
            <div className="logout">
              <span className="">
                {user.displayName || user.email}
              </span> 
              <button className="btn btn-primary btn-sm me-3"
              onClick={()=>{signOut(auth)}}
              >Logout</button>
            </div>

        }
      </nav>
    </div>
    
  );
}
