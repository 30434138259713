import React from "react";
import { Link } from "react-router-dom";
import '../App.css'

export default function Navbar() {
  return (
    <div className="fixed-top border" style={{ backgroundColor: "whitesmoke" }}>
      <nav className="navbar__nativo">
      <Link className="nav-link" to="/">
        <div className="logo">
          <img
            src="logo192.png"
            width={30}
            height={30}
            alt="logo"
          />
        </div>
        </Link>
      </nav>
    </div>

  );
}
